import React, { createContext, useState, useContext, useEffect, useCallback, ReactNode } from 'react';

interface Slug {
  current?: string;
}

interface Series {
  title?: string;
  slug?: Slug;
}

interface FormProduct {
  title?: string;
  slug?: Slug;
  series?: Series;
  sku?: string;
}

// Define the shape of the context
interface GlobalContextProps {
  isMobile: boolean;
  formProduct: FormProduct;
  handleSetFormProduct: () => void;
}

// Provide a default value for the context (optional)
const GlobalContext = createContext<GlobalContextProps | undefined>(undefined);

interface GlobalProviderProps {
  children: ReactNode;
}

// Provider Component
export const GlobalProvider: React.FC<GlobalProviderProps> = ({ children }) => {
  // Initiate states
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [formProduct, setFormProduct] = useState<formProduct>({});

  // Check if the window width corresponds to your mobile breakpoint
  const handleCheckBp = useCallback(() => {
    const isMobileNow = window.matchMedia(`(max-width: 959px)`).matches;

    if (isMobileNow !== isMobile) {
      setIsMobile(isMobileNow);
    }
  }, [isMobile]);

  // Function to set form product title, slug, series & sku
  const handleSetFormProduct = (product: FormProduct) => {
    setFormProduct(product);
  };

  // Set up event listener for resize events
  useEffect(() => {
    handleCheckBp(); // Check immediately on mount

    window.addEventListener('resize', handleCheckBp);

    // Cleanup function to remove the event listener
    return () => window.removeEventListener('resize', handleCheckBp);
  }, [handleCheckBp]);

  return (
    <GlobalContext.Provider value={{ isMobile, formProduct, handleSetFormProduct }}>
      {children}
    </GlobalContext.Provider>
  );
};

// Custom hook to use the global state
export const useGlobalState = () => {
  const context = useContext(GlobalContext);
  if (context === undefined) {
    throw new Error('useGlobalState must be used within a GlobalProvider');
  }
  return context;
};
